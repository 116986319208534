<template>
  <div>
    <v-card v-if="filtersTitle" class="mb-4">
      <v-card-title>{{ filtersTitle }}</v-card-title>

      <div class="d-flex flex-row flex-wrap">
        <v-card-text
          class="col-md-6"
          v-for="filter in filterFields"
          :key="filter.value"
        >
          <component
            :is="filter.component"
            :label="filter.label"
            :value="state.filters[filter.value]"
            @input="(e) => handleInput(e, filter.value)"
            @change="(e) => handleInput(e, filter.value)"
            dense
            hide-details
            outlined
          ></component>
        </v-card-text>
      </div>

      <v-card-actions>
        <v-btn @click="applyFilters" color="primary">Update Table</v-btn>
        <v-btn @click="clearFilters">Reset</v-btn>
      </v-card-actions>
    </v-card>

    <v-card :disabled="isDisabled">
      <v-data-table
        :headers="tableHeaders"
        :items="state.tableData"
        :loading="state.isLoading"
        :page.sync="state.page"
        :server-items-length="state.totalItems"
        :items-per-page.sync="state.itemsPerPage"
        @update:page="handlePageChange"
        no-data-text="No Data"
      >
        <template
          v-for="column in customColumns"
          v-slot:[`item.${column.itemValue}`]="{ item }"
        >
          <slot :name="column.slotName" :item="item"></slot>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import { onMounted, reactive } from "@vue/composition-api";
import { VTextField, VCheckbox } from "vuetify/lib";

export default {
  name: "TableWithFilters",
  props: { options: Object, isDisabled: Boolean, customColumns: Array },
  components: { VTextField, VCheckbox },
  setup({ options }) {
    const state = reactive({
      endpoint: "",
      isLoading: false,

      filters: {},
      tableData: [],

      page: 1,
      itemsPerPage: 0,
      totalItems: 0,
    });

    onMounted(() => {
      state.endpoint = options.endpoint;
      if (options.filterFields)
        for (var filter of options.filterFields) {
          state.filters[filter.value] = "";
        }

      fetchTableData();
    });

    async function fetchTableData() {
      try {
        state.isLoading = true;
        const { data } = await axios.get(state.endpoint, {
          params: { page: state.page, ...state.filters },
        });

        state.tableData = data.results;
        state.page = parseInt(data.page);
        state.itemsPerPage = data.itemsPerPage;
        state.totalItems = data.total;
      } catch (err) {
        console.log(err);
      } finally {
        state.isLoading = false;
      }
    }

    function handleInput(e, target) {
      state.filters[target] = e;
    }

    function handlePageChange(event) {
      state.page = parseInt(event);
      fetchTableData();
    }

    function applyFilters() {
      state.page = 1;
      fetchTableData();
    }

    function clearFilters() {
      state.page = 1;
      for (var f in state.filters) {
        state.filters[f] = "";
      }

      fetchTableData();
    }

    return {
      state,
      filtersTitle: options.filtersTitle,
      filterFields: options.filterFields,
      tableHeaders: options.headers,

      fetchTableData,
      handlePageChange,
      handleInput,
      applyFilters,
      clearFilters,
    };
  },
};
</script>

<style>
.v-data-footer__pagination {
  margin-left: auto !important;
}

.v-data-footer__select {
  display: none;
}
</style>
